.mugumo section {
  padding: 50px 0 50px 0;
  border-bottom: 1px solid #eaeaea;
}
.mugumo section h2 {
  margin-top: 0;
}
.mugumo section p {
  margin-top: 0;
}
.mugumo header .container {
  position: relative;
}
.mugumo .heading {
  margin-top: 60px;
  color: #edf0f5;
}
.mugumo .heading h1 {
  font-weight: 600;
  font-size: 50px;
  margin-bottom: 5px;
  letter-spacing: -0.5px;
}
.mugumo h4 {
  font-weight: 600;
}
.mugumo p {
  color: var(--black);
}
.mugumo .heading span {
  font-size: 13px;
  font-weight: 600;
}
.mugumo .heading p.small {
  margin-top: 10px;
  color: #a7afb8;
  max-width: 260px;
}
.mugumo .client {
  color: #a7afb8;
  background: #f7f9fa;
  padding: 30px 10px;
  margin: 0 0 20px 0;
  text-transform: uppercase;
  font-weight: 600;
}
@media (max-width: 1200px) {
  .mugumo .heading {
    text-align: center;
  }
  .mugumo .heading p.small {
    margin: 10px auto;
  }
  .mugumo .brand-desc {
    display: none;
  }
  .mugumo .navbar .navbar-brand {
    margin-left: 10px;
  }
}
.about {
  font-size: 16px;
  font-weight: 200;
}
.about-us {
  color: var(--white);
}
button {
  outline: none;
}
button {
  border: none !important;
  border-color: #ffffff !important;
  outline: none !important;
}
button:focus {
  border: none !important;
  border-color: #ffffff !important;
  outline: none !important;
}
button:hover {
  border: none !important;
  border-color: #ffffff !important;
  outline: none !important;
}
button:active {
  border: none !important;
  border-color: #ffffff !important;
  outline: none !important;
}
div#clients_logo img {
  height: 100px;
  width: 150px;
}
.back-to-top {
  position: fixed;
  display: none;
  background: var(--blue);
  color: #fff;
  padding: 6px 12px 9px 12px;
  font-size: 16px;
  border-radius: 2px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
}
.back-to-top:focus {
  background: var(--blue);
  color: #fff;
  outline: none;
}
.back-to-top:hover {
  background: var(--black);
  color: var(--white);
}
#services {
  padding: 30px 0 0 0;
}
#services .box {
  padding: 40px;
  margin-bottom: 40px;
  box-shadow: 10px 10px 15px rgba(73, 78, 92, 0.1);
  background: #fff;
  transition: 0.4s;
}
#services .box:hover {
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
}
#services .box .icon {
  float: left;
}
#services .box .icon i {
  color: #444;
  font-size: 64px;
  transition: 0.5s;
  line-height: 0;
  margin-top: 34px;
}
#services .box .icon i:before {
  background: #0c2e8a;
  background: linear-gradient(45deg, var(--black) 0%, #a3ebd5 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#services .box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 22px;
}
#services .box h4 a {
  color: #444;
}
#services .box p {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 24px;
}
#clients {
  padding: 30px 0;
}
#clients img {
  max-width: 100%;
  opacity: 1;
  transition: 0.3s;
  padding: 15px 0;
}
#clients img:hover {
  opacity: 0.5;
}
#clients .owl-nav,
#clients .owl-dots {
  margin-top: 5px;
  text-align: center;
}
#clients .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
}
#clients .owl-dot.active {
  background-color: var(--blue);
}
#portfolio {
  background: #fff;
  padding: 30px 0;
}
#portfolio .portfolio-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: all ease-in-out 0.4s;
}
#portfolio .portfolio-item {
  overflow: hidden;
  position: relative;
  padding: 0;
  vertical-align: middle;
  text-align: center;
}
#portfolio .portfolio-item h2 {
  color: #ffffff;
  font-size: 24px;
  margin: 0;
  text-transform: capitalize;
  font-weight: 700;
}
#portfolio .portfolio-item img {
  transition: all ease-in-out 0.4s;
  width: 100%;
}
#portfolio .portfolio-item:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
#portfolio .portfolio-item:hover .portfolio-overlay {
  opacity: 1;
  background: rgba(0, 0, 0, 0.7);
}
#portfolio .portfolio-info {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#contact {
  min-height: 90vh;
  /* background: url(../img/background/3.png); */
}
#contact .contact-info {
  margin-bottom: 20px;
  text-align: center;
}
#contact .contact-info i {
  font-size: 48px;
  display: inline-block;
  margin-bottom: 10px;
  color: var(--blue);
}
#contact .contact-info address,
#contact .contact-info p {
  margin-bottom: 0;
  color: #000;
}
#contact .contact-info h3 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
  text-transform: uppercase;
  color: #999;
}
#contact .contact-info a {
  color: #000;
}
#contact .contact-info a:hover {
  color: var(--black);
}
#contact .contact-address,
#contact .contact-phone,
#contact .contact-email {
  margin-bottom: 20px;
}
#contact #google-map {
  height: 290px;
  margin-bottom: 20px;
}
#contact .form #sendmessage {
  color: var(--black);
  border: 1px solid var(--black);
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}
#contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}
#contact .form #sendmessage.show,
#contact .form #errormessage.show,
#contact .form .show {
  display: block;
}
#contact .form .validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}
#contact .form input,
#contact .form textarea {
  padding: 10px 14px;
  border-radius: 0;
  box-shadow: none;
  font-size: 15px;
}
#contact .form button[type='submit'] {
  background: var(--black);
  border: 0;
  border-radius: 3px;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  cursor: pointer;
}
#contact .form button[type='submit']:hover {
  background: #2dc899;
}
#footer {
  background: #f2f5f8;
  padding: 0 0 30px 0;
  font-size: 14px;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}
@media (min-width: 768px) {
  #contact .contact-address,
  #contact .contact-phone,
  #contact .contact-email {
    padding: 20px 0;
  }
  #contact .contact-phone {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
}
@media (min-width: 769px) {
  #call-to-action .cta-btn-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}
@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
  }
  #nav-menu-container {
    display: none;
  }
  #mobile-nav-toggle {
    display: inline;
  }
  #about .about-img {
    height: auto;
  }
  #about .about-img img {
    margin-left: 0;
    padding-bottom: 30px;
  }
}
#company {
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}
#company:hover {
  text-decoration: none;
}

.bg-light {
  background-color: #f7f9fa;
}
