@import url(animate.css);
@import url(fonts.css);
@import url(font-awesome/css/font-awesome.min.css);
@import url(custom.css);
@import url(bootstrap.css);
@import url(ionicons/css/ionicons.min.css);

:root {
  --blue: #3498db;
  --green: #5cb85c;
  --dark_orange: #5f0038;
  --black: #000000;
  --white: #fff;
}
.blue {
  color: var(--blue);
}
.green {
  color: var(--green);
}
.orange {
  color: var(--dark_orange);
}
.white {
  color: var(--white);
}
.bg-white {
  background-color: var(--white);
}
::-webkit-scrollbar {
  width: 7.5px;
  height: 7.5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: var(--blue);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--black);
}
body {
  color: #666666;
  font-size: 15px;
  font-family: 'ff-clan-web-pro', Helvetica Neue, Helvetica, sans-serif !important;
  line-height: 1.80857;
}

a {
  color: #1f1f1f;
  text-decoration: none !important;
  outline: none !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0;
  font-weight: normal;
  position: relative;
  padding: 0 0 10px 0;
  font-weight: normal;
  line-height: 120% !important;
  color: #1f1f1f;
  margin: 0;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 21px;
  color: var(--blue);
  margin-top: 25px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 13px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #212121;
  text-decoration: none !important;
  opacity: 1;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  opacity: 0.8;
}

a {
  color: #1f1f1f;
  text-decoration: none;
  outline: none;
}

.dark_bg {
  background: #000;
}

.padding_left_right {
  padding-left: 30px;
  padding-right: 30px;
}

a,
.btn {
  text-decoration: none !important;
  outline: none !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn-custom {
  margin-top: 20px;
  background-color: transparent !important;
  border: 2px solid #ddd;
  padding: 12px 40px;
  font-size: 16px;
}

.lead {
  font-size: 18px;
  line-height: 30px;
  color: #767676;
  margin: 0;
  padding: 0;
}

blockquote {
  margin: 20px 0 20px;
  padding: 30px;
}

ul,
li,
ol {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

button:focus {
  outline: none;
}

.form-control::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
.box {
  position: absolute;
  top: 0;
  left: 0;
  width: 75px;
  height: 75px;
  background-image: none;
  background-size: auto auto;
  background-image: none;
  -webkit-animation: move 2s ease-in-out infinite both;
  animation: move 2s ease-in-out infinite both;
  animation-delay: 0s;
  animation-delay: 0s;
  animation-delay: 0s;
  background-size: 100% 100%;
}

.box:nth-child(1) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.box:nth-child(2) {
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.box:nth-child(3) {
  -webkit-animation-delay: -3s;
  animation-delay: -3s;
}

@-webkit-keyframes move {
  0%,
  100% {
    -webkit-transform: none;
    transform: none;
  }
  12.5% {
    -webkit-transform: translate(40px, 0);
    transform: translate(40px, 0);
  }
  25% {
    -webkit-transform: translate(80px, 0);
    transform: translate(80px, 0);
  }
  37.5% {
    -webkit-transform: translate(80px, 40px);
    transform: translate(80px, 40px);
  }
  50% {
    -webkit-transform: translate(80px, 40px);
    transform: translate(80px, 40px);
  }
  62.5% {
    -webkit-transform: translate(30px, 60px);
    transform: translate(30px, 60px);
  }
  75% {
    -webkit-transform: translate(0, 80px);
    transform: translate(0, 40px);
  }
  87.5% {
    -webkit-transform: translate(0, 40px);
    transform: translate(0, 40px);
  }
}

@keyframes move {
  0%,
  100% {
    -webkit-transform: none;
    transform: none;
  }
  12.5% {
    -webkit-transform: translate(40px, 0);
    transform: translate(40px, 0);
  }
  25% {
    -webkit-transform: translate(80px, 0);
    transform: translate(80px, 0);
  }
  37.5% {
    -webkit-transform: translate(80px, 40px);
    transform: translate(80px, 40px);
  }
  50% {
    -webkit-transform: translate(80px, 80px);
    transform: translate(80px, 80px);
  }
  62.5% {
    -webkit-transform: translate(40px, 80px);
    transform: translate(40px, 80px);
  }
  75% {
    -webkit-transform: translate(0, 80px);
    transform: translate(0, 80px);
  }
  87.5% {
    -webkit-transform: translate(0, 40px);
    transform: translate(0, 40px);
  }
}

#scroll-to-top {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 10px;
  right: 20px;
  display: none;
  font-size: 25px;
  border-radius: 0;
  transition: 0.2s;
  letter-spacing: 1px;
  text-align: center;
  line-height: 40px;
  color: #ffffff;
  font-weight: 900;
  border-radius: 100%;
}

a:hover,
a:focus {
  color: var(--dark_orange);
  text-decoration: underline;
}

.heading_main.text_align_left {
  justify-content: flex-start;
}

.padding_top_0 {
  padding-top: 0 !important;
}

/*------------------------------------------------------------------ HEADER -------------------------------------------------------------------*/

.header_top {
  min-height: 98px;
  padding: 8px 0;
  background: #fff;
}

.site_information ul {
  float: right;
  width: 95%;
  padding: 21px 0;
}

a.join_bt {
  background: var(--dark_orange);
  width: 180px;
  text-align: center;
  height: 38px;
  line-height: 38px;
  border-radius: 75px;
  color: #fff;
  font-weight: 300;
}

a.join_bt:hover,
a.join_bt:focus {
  background: #222;
  color: #fff !important;
}

.site_information {
  float: left;
  width: 66.66%;
}

.site_information ul {
  list-style: none;
}

.site_information ul li {
  float: left;
  width: 33.33%;
  display: flex;
  justify-content: center;
  font-size: 17px;
  font-weight: 300;
}

.site_information ul li img {
  margin-right: 10px;
}

.logo_section {
  width: 33.33%;
}

.top-header .navbar {
  padding: 0;
  min-height: 60px;
  float: left;
}

.top-header {
  position: relative;
  top: 0px;
  left: 0px;
  margin: 0;
  z-index: 20;
  background-position: left;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: auto 100%;
  width: 100%;
}

.section {
  float: left;
  width: 100%;
}

.ulockd-home-slider {
  float: left;
  width: 100%;
}

.top-header .navbar .navbar-collapse ul li a {
  text-transform: none;
  font-size: 17px;
  padding: 10px 15px;
  font-weight: 400;
  overflow: hidden;
  color: var(--green);
  position: relative;
  z-index: 2;
}

a.navbar-brand {
  left: 35px;
  position: relative;
}

.top-header .navbar .navbar-collapse ul li a.active {
  background: transparent;
  color: var(--blue);
}

.top-header .navbar .navbar-collapse ul li a:hover,
.top-header .navbar .navbar-collapse ul li a:focus {
  background: transparent;
  color: var(--blue);
}
.top-header .navbar .navbar-collapse ul li {
  margin: 0 2px;
}

.top-header.fixed-menu {
  width: 100%;
  position: fixed;
  box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.06);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  background: #fff;
  z-index: 20;
}

.header_bottom {
  float: left;
  width: 100%;
  position: absolute;
  bottom: -60px;
}

.header_bottom::after {
  width: 50%;
  display: block;
  right: 0;
  content: '';
  background: var(--dark_orange);
  height: 60px;
  position: absolute;
  top: 0;
}

.navbar-toggler {
  border: 2px solid #fff;
  border-radius: 0;
  margin: 15px 0;
  padding: 8px 8px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: 9;
  position: relative;
}

.navbar-toggler span {
  background: #fff;
  display: block;
  width: 22px;
  height: 2px;
  margin: 0 auto;
  margin-top: 0px;
  margin-top: 0px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.navbar-toggler span + span {
  margin-top: 5px;
}

.navbar-toggler:hover {
  border: 2px solid #222;
}

.navbar-toggler:hover span {
  background: #222;
}

.theme_color {
  color: var(--green);
}

.menu_orange_section {
  float: right;
  position: relative;
  width: 95%;
}

.menu_orange_section::after {
  width: 70px;
  height: 60px;
  position: relative;
  content: '';
  display: block;
  background: var(--green);
  left: -35px;
  transform: skew(30deg);
}

.main_bt {
  width: 195px;
  height: 55px;
  float: left;
  text-align: center;
  line-height: 55px;
  border: solid var(--dark_orange) 1px;
  font-size: 18px;
  margin-top: 10px;
  background: #fff;
  font-weight: 500;
}

.paddding_left_15 {
  padding-left: 15px;
}

/*------------------------------------------------------------------ Banner -------------------------------------------------------------------*/

.home-slider {
  position: relative;
  height: 540px;
}

.lbox-caption {
  display: table;
  height: 100% !important;
  width: 100% !important;
  left: 0 !important;
}

.lbox-caption {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 10;
}

.lbox-details {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  height: 100%;
  padding: 22% 0%;
}

.lbox-details::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.lbox-details h1 {
  font-size: 54px;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  font-weight: 600;
  position: relative;
  z-index: 3;
}

.lbox-details h2 {
  font-size: 48px;
  color: #ffffff;
  font-weight: 300;
  position: relative;
  z-index: 3;
}

.lbox-details p {
  color: #ffffff;
  position: relative;
  z-index: 3;
}

.lbox-details p strong {
  color: #70c6eb;
  font-size: 40px;
  font-family: 'Montserrat', sans-serif;
}

.lbox-details a.btn {
  background: #ffffff;
  padding: 10px 20px;
  font-size: 20px;
  text-transform: capitalize;
  color: #3a4149;
  border-radius: 0px;
  position: relative;
  z-index: 3;
}

.lbox-details a.btn:hover {
  background: #70c6eb;
}

.pogoSlider-nav-btn {
  background: #fff;
}

.pogoSlider-nav-btn--selected {
  background: var(--dark_orange) !important;
}

.pogoSlider--navBottom .pogoSlider-nav {
  bottom: 35px;
}

.pogoSlider--dirCenterHorizontal .pogoSlider-dir-btn {
  display: none;
}

.img-responsive {
  max-width: 100%;
}

.slide_text h3 {
  font-size: 85px;
  font-weight: 200;
  padding: 0;
  line-height: normal;
  text-transform: none;
}

.slide_text h3 strong {
  font-size: 170px;
  text-transform: uppercase;
  line-height: normal;
}

.slide_text h4 {
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.slide_text p {
  color: #f2184f;
  font-size: 20px;
  font-weight: 300;
  padding-top: 0;
  line-height: normal;
  margin-bottom: 0;
}

.start_exchange_bt {
  border: solid #fff 1px;
  color: #fff;
  padding: 7px 25px;
  float: left;
  width: 220px;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
}

.contact_bt {
  width: 180px;
  height: 50px;
  background: #f2184f;
  color: #fff;
  float: left;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-top: 50px;
  border-radius: 5px;
}

.contact_bt:hover,
.contact_bt:focus {
  color: #fff;
}

.dark_bg .contact_bt {
  margin-top: 30px;
}

.slide_text {
  margin-top: 250px;
  padding-left: 0;
}

/*------------------------------------------------------------------ About -------------------------------------------------------------------*/

.tooltip-1 {
  display: inline-block;
  position: relative;
  color: #70c6eb;
  opacity: 1;
}

.tooltip__wave {
  width: 30%;
  height: 20px;
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0px;
  margin: 0 auto;
  overflow: hidden;
}

.tooltip__wave span {
  position: absolute;
  left: -100%;
  width: 200%;
  height: 100%;
  /* background: url(../images/wave.svg) repeat-x center center; */
  background-size: 50% auto;
}

.inner_page_banner h3 {
  font-size: 40px;
  color: #fff;
  padding: 40px 15px;
}

.inner_page_banner h2 {
  font-size: 25px;
  color: #fff;
  padding-bottom: 40px;
}

.inner_page_banner {
  float: left;
  width: 100%;
  padding-top: 60px;
  /* height: 740px; */
  /* background: #111; */
}

.about-box {
  padding: 70px 0px;
}

.title-box {
  text-align: center;
  margin-bottom: 30px;
}

.title-box h2 {
  font-size: 75px;
  font-family: 'Great Vibes', cursive;
  color: #e91327;
  font-weight: 400;
  padding: 0;
}

.title-box h2 span {
  color: #70c6eb;
  text-decoration: underline;
}

.about-main-info h2 span {
  color: #70c6eb;
  text-decoration: underline;
}

.about-main-info {
  margin-bottom: 30px;
}

.about_bg {
  /* background-image: url('../images/about_bg.png'); */
  background-repeat: no-repeat;
  background-position: left center;
}

.about-img {
  padding: 30px 0px;
}

.about-img img {
  border-radius: 10px;
}

.about-m ul {
  display: block;
  text-align: center;
  padding-bottom: 30px;
}

.about-m ul li {
  display: inline-block;
  text-align: center;
}

.about-m ul li a {
  background: #528780;
  color: #ffffff;
  width: 38px;
  height: 38px;
  text-align: center;
  line-height: 38px;
  display: block;
  border-radius: 50px;
  margin: 0px 5px;
}

.about-m ul li a:hover {
  background: #333333;
  color: #ffffff;
}

.about-main-info h2 {
  font-size: 45px;
  color: #e91327;
  font-weight: 600;
}

.about-main-info a {
  border-radius: 2px;
  transition: 0.2s;
  padding: 10px 25px;
  background: #e91327;
  color: #ffffff;
  font-weight: 300;
  font-size: 15px;
  border-radius: 0 15px;
  margin-top: 10px;
}

.about-main-info a:hover {
  color: #ffffff;
}

.about-main-info div.full > img {
  height: 500px;
}

.hvr-radial-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  background: #070500;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  color: #fff;
  float: left;
  width: 165px;
  height: 45px;
  text-align: center;
  line-height: 45px;
}

.hvr-radial-out::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #d66c0b;
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-radial-out:hover::before,
.hvr-radial-out:focus::before,
.hvr-radial-out:active::before {
  -webkit-transform: scale(2);
  transform: scale(2);
}

a.hvr-radial-out:hover,
a.hvr-radial-out:focus {
  color: #fff;
}

/*------------------------------------------------------------------ Services -------------------------------------------------------------------*/

.service_blog {
  float: left;
  width: 100%;
  border: solid #555 1px;
  padding: 30px;
  margin-top: 30px;
  transition: ease all 0.2s;
}

.services_blog {
  margin-bottom: 30px;
}

.service_blog:hover,
.service_blog:focus {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

.service_blog h4 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  border-bottom: solid #222 2px;
  color: #222;
  margin-bottom: 15px;
}

.effect-service {
  position: relative;
  height: auto;
  text-align: center;
  cursor: pointer;
  border: 8px solid #eee;
  margin-bottom: 30px;
}

figure.effect-service {
  background: -webkit-linear-gradient(-45deg, #ec65b7 0%, #05e0d8 100%);
  background: linear-gradient(-45deg, #ec65b7 0%, #05e0d8 100%);
}

figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

figure.effect-service img {
  opacity: 0.85;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

figure figcaption {
  padding: 10px;
  color: #fff;
  text-transform: capitalize;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-service h2 {
  padding: 20px;
  width: 50%;
  height: 50%;
  text-align: left;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(10px, 10px, 0);
  transform: translate3d(10px, 10px, 0);
}

figure.effect-service h2 {
  background: #e91327;
  padding: 13px;
  width: 94%;
  height: 58px;
  border: 0;
  margin: 0;
  font-weight: 200;
  font-size: 34px;
  color: #ffffff;
  text-align: center;
  font-family: 'Great Vibes', cursive;
}

figure.effect-service p {
  padding: 15px;
  width: 100%;
  height: 80%;
  font-size: 14px;
  border: 2px solid #fff;
  margin: 0;
  font-weight: 300;
  display: none;
}

figure.effect-service p {
  float: right;
  padding: 20px;
  text-align: left;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

figure.effect-service:hover img {
  opacity: 0.6;
}

figure.effect-service:hover p {
  opacity: 1;
}

figure.effect-service:hover {
  border: 8px solid #e91327;
}

.services_blog h4 {
  color: #000;
  font-size: 32px;
  text-align: center;
  font-weight: 500;
  box-shadow: 0 0 15px -8px #000;
  margin: 0;
  padding: 0;
  min-height: 110px;
  line-height: 110px !important;
}

.services_blog img {
  max-height: 370px;
  width: 100%;
}

/*------------------------------------------------------------------ Gallery -------------------------------------------------------------------*/

.gallery-box {
  padding: 70px 0px 120px;
}

.gallery-box ul li {
  position: relative;
  width: 31.33%;
  margin: 0 1% 20px !important;
  padding: 0px;
  float: left;
  border: none;
  overflow: hidden;
  margin-bottom: 0px;
}

.gallery-box ul li a {
  position: relative;
  display: inline-block;
  border: 4px solid #ffffff;
}

.gallery-box ul li a::before {
  content: '';
  position: absolute;
  background: rgba(233, 19, 39, 0.9);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 100%;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
}

.gallery-box ul li a .overlay {
  background: #70c6eb;
  color: #3a4149;
  font-size: 22px;
  text-align: center;
  width: 38px;
  height: 38px;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
}

.gallery-box ul li a:hover::before {
  top: 0;
  opacity: 1;
}

.gallery-box ul li a:hover .overlay {
  opacity: 1;
}

.gallery-box ul li a:hover {
  border: 4px solid #e91327;
}

.gallery-box ul li a .overlay {
  background: #fff;
  color: #e91327;
}

/*------------------------------------------------------------------ Properties -------------------------------------------------------------------*/

.properties-box {
  padding: 70px 0px;
  background-color: #f2f3f5;
}

.single-team-member {
  position: relative;
  margin-top: 30px;
  border: 10px solid #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.filter-button-group {
  margin-bottom: 30px;
}

.filter-button-group button {
  border-radius: 2px;
  transition: 0.2s;
  letter-spacing: 1px;
  padding: 10px 18px;
  background: #57cef8;
  color: #ffffff;
  cursor: pointer;
  border: none;
}

.filter-button-group button:hover {
  color: #ffffff;
}

.properties-single {
  margin-bottom: 30px;
  background: #ffffff;
  transition: all 0.5s ease 0s;
}

.fuit {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.fuit a {
  background: #57cef8;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  color: #ffffff;
}

.for-sal {
  background: #3a4149;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #ffffff;
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 13px;
  z-index: 10;
}

.pro-price {
  background: #57cef8;
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #ffffff;
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 13px;
  z-index: 10;
}

.properties-img {
  position: relative;
  overflow: hidden;
}

.properties-img img {
  -webkit-transition: all 1.5s;
  -moz-transition: all 1.5s;
  -o-transition: all 1.5s;
  -ms-transition: all 1.5s;
  transition: all 1.5s;
  transition-delay: 0s;
  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  -ms-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.properties-single:hover .properties-img img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.hover-box {
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: alpha(opacity=0);
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  -webkit-transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  -ms-transition: all 0.9s ease;
  transition: all 0.9s ease;
  transition-delay: 0s;
  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  -ms-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.properties-single:hover .hover-box {
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  opacity: 1;
  top: 0;
}

.properties-dit {
  padding: 15px;
}

.properties-dit h3 {
  font-size: 20px;
}

.properties-dit ul {
  padding: 10px 0px;
}

.properties-dit ul li {
  float: left;
  width: 33.33%;
  font-size: 12px;
  line-height: 30px;
}

.properties-dit ul li i {
  float: right;
  padding: 7px 10px;
  font-size: 15px;
  color: #57cef8;
}

.properties-dit p {
  margin: 0px;
}

.properties-dit p i {
  padding-right: 10px;
}

/*------------------------------------------------------------------ counter ----------------------------------------------------------------*/

h2.timer.count-title.count-number {
  color: #f43866;
  font-size: 60px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  line-height: 60px;
}

p.count-text {
  color: #f43866;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  border-bottom: solid #fff 5px;
  padding-bottom: 10px;
  width: 50%;
}

/*------------------------------------------------------------------ Team -------------------------------------------------------------------*/

.team-box {
  padding: 70px 0px;
}

.team_member_img img {
  border: solid #ece9e2 20px;
  border-radius: 100%;
  width: 300px;
  height: 300px;
}

#team_slider h3 {
  margin-bottom: 10px;
  color: #000;
  font-size: 22px;
  font-weight: 600;
  padding: 0;
  margin-top: 25px;
}

#team_slider p {
  padding: 0 43px;
}

.team_member_img {
  position: relative;
}

.team_member_img .social_icon_team {
  position: absolute;
  top: 19px;
  left: 42px;
  width: 76%;
  height: 87%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: rgba(244, 56, 102, 0.7);
  opacity: 0;
  transition: ease all 0.2s;
}

.team_member_img:hover .social_icon_team,
.team_member_img:focus .social_icon_team {
  opacity: 1;
}

.our-team {
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.our-team:before,
.our-team:after {
  content: '';
  width: 130px;
  height: 150px;
  background: #57cef8;
  position: absolute;
  z-index: -1;
}

.our-team:before {
  bottom: -20px;
  left: 0;
}

.our-team:after {
  top: -20px;
  right: 0;
}

.our-team .pic {
  margin: 8px;
  position: relative;
  border: 3px solid #57cef8;
  transition: all 0.5s ease 0s;
}

.our-team:hover .pic {
  border-color: #3a4149;
}

.our-team .pic:after {
  content: '';
  width: 100%;
  height: 0;
  background: #57cef8;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform-origin: 0 0 0;
  transition: all 0.5s ease 0s;
}

.our-team:hover .pic:after {
  height: 100%;
  opacity: 0.85;
}

.our-team img {
  width: 100%;
  height: auto;
}

.our-team .team-content {
  width: 100%;
  position: absolute;
  top: -50%;
  left: 0;
  transition: all 0.5s ease 0.2s;
}

.our-team:hover .team-content {
  top: 38%;
}

.our-team .title {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
  margin: 0px;
}

.our-team .post {
  font-size: 14px;
  color: #fff;
  line-height: 26px;
  text-transform: capitalize;
}

.our-team .social {
  padding: 0;
  margin: 40px 0 0 0;
  list-style: none;
}

.our-team .social li {
  display: inline-block;
}

.our-team .social li a {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  border: 1px solid #fff;
  font-size: 18px;
  color: #fff;
  margin: 0 7px;
  transition: all 0.5s ease 0s;
}

.our-team .social li a:hover {
  background: #fff;
  color: #00bed3;
}

/*------------------------------------------------------------------ Blog -------------------------------------------------------------------*/

.blog-box {
  padding: 70px 0px;
  background-color: #f2f3f5;
}

.blog-inner {
  background: #ffffff;
  text-align: center;
  margin-bottom: 30px;
  border: 10px solid #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.blog-img {
  margin-bottom: 15px;
  overflow: hidden;
}

.blog-img img {
  transition: all 0.9s ease 0s;
}

.blog-inner:hover .blog-img img {
  -moz-transform: scale(1.5) rotate(-10deg);
  -webkit-transform: scale(1.5) rotate(-10deg);
  -ms-transform: scale(1.5) rotate(-10deg);
  -o-transform: scale(1.5) rotate(-10deg);
  transform: scale(1.5) rotate(-10deg);
}

.blog-inner a {
  border-radius: 2px;
  transition: 0.2s;
  letter-spacing: 1px;
  padding: 10px 18px;
  background: #57cef8;
  color: #ffffff;
}

.blog-inner a:hover {
  color: #ffffff;
}

/*------------------------------------------------------------------ Contact -------------------------------------------------------------------*/

.contact-box {
  padding: 70px 0px;
}

.left-contact h2 {
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 30px;
}

.cont-line {
  overflow: hidden;
  margin-bottom: 30px;
}

.icon-b {
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  font-size: 25px;
  margin-right: 15px;
  color: #fff;
  border-radius: 100%;
  background: #f2184f;
}

.dit-right h4 {
  font-size: 16px;
  color: #3a4149;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.dit-right p {
  font-size: 14px;
  margin-top: 0;
}

.dit-right a {
  font-size: 14px;
  color: #3a4149;
  font-weight: 300;
  line-height: normal;
}

.dit-right a:hover {
  color: #57cef8;
}

.contact-block .form-group .form-control {
  background: #333;
  height: 48px;
  font-size: 14px;
  border: 0;
  padding: 5px 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  font-weight: 200;
  letter-spacing: 0px;
}

.submit-button .btn-common {
  background-color: #f2184f;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding: 0 20px;
  border: 0;
  outline: 0;
  text-transform: uppercase;
  border-radius: 0px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 0;
  opacity: 1;
}

.contact-block .form-group textarea.form-control {
  height: 150px;
  padding-top: 15px;
}

.submit-button .btn-common:hover {
  background-color: #111;
  opacity: 1;
}

.custom-select {
  height: 45px;
  font-size: 16px;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(45px + 2px);
}

.help-block ul li {
  color: red;
}

.menu_footer li a {
  font-family: 'poppins', sans-serif;
  font-size: 15px;
  font-weight: 300;
}

.menu_footer li a:hover,
.menu_footer li a:focus {
  color: #d66c0b !important;
}

.menu_footer li {
  margin: 3px 0;
}

.footer-box {
  background: #3a1e02;
  padding: 50px 0;
}

.footer-box .footer-company-name {
  text-align: center;
  margin: 0px;
  color: #333;
  font-size: 13px;
  font-weight: 400;
  padding-top: 2px;
}

footer.footer-box h3 {
  font-size: 30px;
  border-bottom: solid #d66c0b 4px;
  margin-bottom: 15px;
}

.footer-box .footer-company-name a:hover {
  color: #e91327;
}

footer.footer-box h3 {
  font-size: 30px;
  border-bottom: solid #d66c0b 4px;
  margin-bottom: 25px;
}

/*------------------------------------------------------------------ Subscribe -------------------------------------------------------------------*/

.subscribe-box {
  padding: 70px 0px;
  background: #e91327;
}

.subscribe-inner {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.subscribe-inner h2 {
  font-size: 80px;
  font-weight: 600;
  color: #ffffff;
  font-family: 'Great Vibes', cursive;
  padding: 0;
}

.subscribe-inner p {
  color: #cccccc;
}

.subscribe-inner .form-group .form-control-1 {
  width: 100%;
  padding: 12px 15px;
  border-radius: 0px;
  border: none;
}

.subscribe-inner .form-group button {
  border-radius: 0;
  transition: 0.2s;
  padding: 10px 18px;
  background: #222;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-weight: 300;
  letter-spacing: 0.1px;
  border-radius: 0 15px 0 15px;
}

.about-main-info h2 img {
  margin-top: -10px;
}

p {
  margin-top: 5px;
  margin-bottom: 1rem;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  color: #4a4a49;
}

.text_align_left {
  text-align: left;
}

.text_align_center {
  text-align: center;
}

.text_align_right {
  text-align: right;
}

.text_align_left_img img {
  float: left;
}

.text_align_right_img img {
  float: right;
}

.red_bg {
  background: #e91327;
}

.white_fonts p,
.white_fonts h1,
.white_fonts h2,
.white_fonts h3,
.white_fonts h4,
.white_fonts h5,
.white_fonts h6,
.white_fonts ul,
.white_fonts ul li,
.white_fonts ul li a,
.white_fonts ul i,
.white_fonts .post_info i,
.white_fonts div,
.white_fonts a.read_more {
  color: #fff !important;
}

.red_bg .about-main-info a {
  background: #fff;
  color: #e91327;
}

.testimonial_img {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.mfp-figure figure .mfp-bottom-bar {
  display: none;
}

#client_slider .carousel-indicators {
  bottom: -45px;
}

#client_slider .carousel-indicators li {
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background: #999;
}

#client_slider .carousel-indicators li.active {
  background: #e91327;
}

.layout_padding {
  padding-top: 75px;
  padding-bottom: 75px;
}

.heading_main h2 {
  font-size: 45px;
  letter-spacing: -0.5px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  position: relative;
}

.heading_main h2::after {
  width: 250px;
  height: 8px;
  background: var(--blue);
  content: '';
  display: block;
  transform: skew(-30deg);
  margin-top: 3px;
}

.heading_main p.large {
  font-size: 20px;
  color: #605f5f;
  position: relative;
  padding: 0;
  font-weight: 500;
  margin: 0;
}

.heading_main p.small_tag {
  color: #000;
  font-size: 18px;
  text-transform: uppercase;
  line-height: normal;
  font-weight: 600;
  margin: 0;
}

.center {
  display: flex;
  justify-content: center;
}

p.section_count {
  font-size: 155px;
  margin: 0 45px 0 0;
  color: #cccac5;
  font-weight: 400;
  line-height: 70px;
}

.padding_0 {
  padding: 0;
}

.full {
  float: left;
  width: 100%;
}

.heading_main {
  float: left;
  display: flex;
  margin-bottom: 45px;
  width: 100%;
  justify-content: center;
}

.theme_bg {
  background: var(--blue);
}

h3.small_heading {
  font-size: 35px;
  font-weight: 600;
  line-height: normal;
}

.margin-top_20 {
  margin-top: 20px;
}

.margin-top_30 {
  margin-top: 30px;
}

.margin-bottom_30 {
  margin-bottom: 30px;
}

.carousel a.carousel-control-prev {
  background: #f43866;
  opacity: 1;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  top: 40%;
  left: -80px;
}

.carousel a.carousel-control-next {
  background: #f43866;
  opacity: 1;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  top: 40%;
  right: -80px;
}

#demo {
  margin-bottom: 20px;
}

ul.social_icon li a {
  width: 35px;
  height: 35px;
  background: #fff;
  float: left;
  border-radius: 100%;
  text-align: center;
  font-size: 16px;
}

ul.social_icon li i {
  color: #000 !important;
  line-height: 35px;
}

ul.social_icon li {
  float: left;
  margin: 0 5px;
}

.inner_page .top-header {
  position: relative;
}

.inner_page .inner_page_header {
  background: #1f1f1f;
  padding: 40px 0;
}

.inner_page .inner_page_header h3 {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
}

.footer_bottom {
  background: var(--dark_orange);
  padding: 0 0;
  width: 100%;
  min-height: 82px;
  float: left;
  text-align: center;
}

.footer_bottom p.crp {
  float: left;
  margin: 0;
  color: #fff;
  font-size: 16px;
  text-align: center;
  width: 100%;
  line-height: 60px;
}

.footer_menu ul {
  list-style: none;
  width: 100%;
  float: left;
}

.footer_blog li a {
  font-size: 14px;
  font-weight: 300 !important;
  color: #acaba9 !important;
}

.recent_post_footer p {
  color: #acaba9 !important;
  font-size: 13px;
}

.footer_blog h3 {
  margin-bottom: 10px;
}

.newsletter_form input {
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
}

.newsletter_form button {
  background: #d66c0b;
  color: #fff;
  border: none;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 25px;
  border-radius: 5px;
  cursor: pointer;
}

.news_blog {
  position: relative;
  background: #eee;
  min-height: 280px;
  text-align: center;
  margin: 15px 0;
}

.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(21, 12, 3, 0.65);
  opacity: 0;
  transition: ease all 0.25s;
}

.news_blog:hover .overlay,
.news_blog:focus .overlay {
  opacity: 1;
}

.main_bt.transparent {
  background: transparent;
  border-color: #fff;
  color: #fff;
  cursor: pointer;
}

.blog_details {
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--dark_orange);
  padding: 15px 0 5px;
  text-align: center;
  transition: ease all 0.25s;
}

.news_blog:hover .blog_details,
.news_blog:focus .blog_details {
  opacity: 0;
}

.blog_details h3 {
  color: #fff;
  font-size: 25px;
  margin: 0;
  padding: 0;
}

.blog_details p {
  color: #fff;
  font-size: 15px;
  line-height: normal;
}

.footer_blog ul li {
  margin-bottom: 15px;
  display: flex;
  line-height: normal;
}

.footer_blog ul li img {
  width: 25px;
  height: 25px;
  margin-right: 15px;
  position: relative;
  top: 1px;
}

footer {
  width: 100%;
  float: left;
}

footer.footer-box p {
  font-size: 17px;
}

.footer_blog {
  float: left;
  width: 100%;
}

ul.bottom_menu li a {
  color: #8d8d8c;
}

ul.bottom_menu li {
  float: left;
  font-size: 14px;
  font-weight: 300;
  margin-left: 20px;
}

.bottom_menu {
  float: right;
}

.innerpage_banner {
  background-color: #f9f9f9;
  min-height: 150px;
  text-align: center;
  /* background-image: url('../images/slider-01.jpg'); */
  background-size: cover;
  background-position: center center;
}

.innerpage_banner h2 {
  margin: 55px 0 0;
  padding: 0;
  font-size: 30px;
  text-align: left;
  font-weight: 500;
  position: relative;
}

#demo .img-responsive {
  width: 100%;
}

.top-header.fixed-menu .search-btn {
  background: #111;
}

.top-header.fixed-menu .search-btn img {
  width: 25px;
}

.top-header.fixed-menu .search-box:hover .search-txt {
  width: 240px;
  padding: 0 10px;
  border: solid #000 1px;
  height: 41px;
}

.contact_form {
  background-size: cover;
  background-position: center center;
  min-height: 650px;
  padding: 80px 0;
}

.field input {
  width: 100%;
  background: #0e0606;
  min-height: 65px;
  border: none;
  margin: 15px 0;
  color: #fff;
  padding: 0 25px;
  font-size: 17px;
  font-weight: 300;
}

.field textarea {
  width: 100%;
  background: #0e0606;
  min-height: 120px;
  border: none;
  margin: 15px 0;
  color: #fff;
  padding: 25px;
  font-size: 17px;
  font-weight: 300;
}

.contact_form_inner button {
  width: 210px;
  height: 60px;
  border: none;
  font-size: 20px;
  color: #050000;
  line-height: 62px;
  cursor: pointer;
  transition: ease all 0.5s;
}

.contact_form_inner button:hover,
.contact_form_inner button:focus {
  color: #fff;
  background: #050000;
}

.bg-white {
  background-color: var(--white);
}

div#slider {
  height: 500px;
  margin-top: 30px;
}
img.intro-img {
  height: 340px;
  width: 100%;
  padding: 0;
}
@media only screen and (max-width: 1024px) {
  div#slider {
    margin-top: 0;
  }
  div#our_mission {
    background-color: var(--white);
  }
  button.button {
    font-size: 18px !important;
    min-width: 250px;
  }
  img.others-img {
    height: 400px !important;
  }
  img.tile-img {
    height: 300px !important;
  }
  div#homeIntro {
    height: 500px !important;
  }
  div#defination {
    padding: 30px !important;
  }
}
ul.navbar-nav li {
  cursor: pointer;
}
ul.navbar-nav li.active a {
  color: var(--blue) !important;
}
img.capability {
  width: 100%;
  height: 100%;
  border: 1px solid var(--dark_orange);
  cursor: pointer;
  transition: 0.3s;
}
img.capability:hover {
  cursor: pointer;
  transition: 0.3s;
  opacity: 0.9;
}
div#capability {
  background: url(../img/background/1.png);
}
.wow-box {
  box-shadow: 10px 10px 15px rgba(73, 78, 92, 0.1);
  transition: 0.4s;
}
.wow-box:hover {
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
}
.wow-box .icon {
  float: left;
}
.wow-box .icon i {
  color: #444;
  font-size: 64px;
  transition: 0.5s;
  line-height: 0;
  margin-top: 34px;
}
.wow-box .icon i:before {
  background: #0c2e8a;
  background: linear-gradient(45deg, var(--black) 0%, #a3ebd5 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.wow-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 22px;
}
.wow-box h4 a {
  color: #444;
}
div#client_brands {
  background: url(../img/background/2.png);
}
p.orange {
  font-weight: 500;
}
div#practice {
  background: url(../img/background/4.png);
  min-height: 90vh;
}
.button {
  height: 60px;
  border: none;
  font-size: 20px;
  color: var(--dark_orange);
  line-height: 62px;
  cursor: pointer;
  transition: ease all 0.5s;
  min-width: 350px;
}
.button:hover,
.button:focus {
  color: #fff;
  background: #050000;
}
div#others {
  background: url(../img/background/3.png);
}
div.back-button {
  text-align: center;
  padding: 20px 0 20px 0;
}
div#communications {
  background: url(../img/background/1.png);
}
span.underline {
  text-decoration: underline;
}
img.comms-img {
  height: 400px;
  width: 100%;
  padding: 0;
  cursor: pointer;
  transition: 0.3s;
}
img.rounded-img:hover {
  cursor: pointer;
  transition: 0.3s;
  opacity: 0.9;
  border-radius: 30px;
}
img.others-img {
  height: 500px;
  width: 100%;
  padding: 0;
  cursor: pointer;
  transition: 0.3s;
}
/* only small desktops */
/* tablets */
@media (min-width: 992px) and (max-width: 1199px) {
  .countdown {
    height: 285px;
  }
  .top-header .navbar .navbar-collapse ul li a {
    font-size: 17px;
  }
  .lbox-details h2 {
    font-size: 26px;
  }
  .contact_bt {
    margin-top: 25px;
  }
  .slide_text {
    margin-top: 70px;
  }
  .social_icon {
    margin-bottom: 30px;
  }
  .top-header .navbar .navbar-collapse ul li a {
    padding: 7px 15px;
  }

  .team_member_img .social_icon_team {
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
  }

  .heading_main h2 {
    font-size: 35px;
  }

  .slide_text h3 {
    font-size: 55px;
  }

  .slide_text h3 strong {
    font-size: 110px;
    text-transform: uppercase;
    line-height: normal;
  }

  .services_blog h4 {
    font-size: 25px;
  }
}
/* only small tablets */
@media (min-width: 768px) and (max-width: 991px) {
  .top-header .navbar .navbar-collapse ul li a {
    padding: 5px 15px;
  }
  .about-img {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .gallery-box ul li {
    width: 33.33%;
  }
  .top-header {
    position: relative;
  }
  .lbox-details {
    display: block;
    position: relative;
    width: 100%;
  }
  .countdown #timer div#days,
  .countdown #timer div#hours,
  .countdown #timer div#minutes,
  .countdown #timer div#seconds {
    position: inherit;
    margin: 12px 0px;
  }
  .lbox-details::before {
    background: rgba(0, 0, 0, 0.9);
  }
  .countdown {
    height: 188px;
    border-radius: 0px;
  }
  .countdown #timer div {
    width: 23%;
    font-size: 24px;
  }
  figure.effect-service h2 {
    font-size: 12px;
  }
  .top-header {
    background-image: none;
    background-color: #fff;
  }
  .search-box {
    top: 12px;
    right: 15px;
  }
  .slide_text h3 {
    font-size: 65px;
  }
  .slide_text h3 strong {
    font-size: 125px;
  }
  .search-btn {
    background: #111;
  }
  .search-box:hover .search-txt {
    width: 240px;
    padding: 0 10px;
    border: solid #000 1px;
    height: 41px;
  }
  .top-header .navbar .navbar-collapse ul li a:hover,
  .top-header .navbar .navbar-collapse ul li a:focus {
    color: #0892fd;
  }
  .slide_text p {
    display: none;
  }
  .contact_bt {
    margin-top: 10px;
  }
  .slide_text {
    margin-top: 60px;
  }
  #demo .img-responsive {
    width: 100%;
    margin-bottom: 30px;
  }
  .carousel a.carousel-control-prev {
    left: 20px;
  }
  .carousel a.carousel-control-next {
    right: 20px;
  }
  .team_member_img img {
    border: solid #ece9e2 10px;
    border-radius: 100%;
    width: 210px;
    height: 210px;
  }
  .team_member_img .social_icon_team {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #team_slider p {
    padding: 0;
  }

  .header_bottom::after {
    width: 50%;
    display: block;
    right: 0;
    content: '';
    background: #ff880e;
    height: 66px;
    position: absolute;
    top: 0;
    display: none;
  }

  .header_bottom {
    float: left;
    width: 100%;
    position: relative;
    bottom: 0;
  }

  .menu_orange_section::after {
    width: 70px;
    height: 66px;
    position: relative;
    content: '';
    display: block;
    background: #ff880e;
    left: -50px;
    transform: skew(30deg);
    display: none;
  }
  .menu_orange_section {
    float: right;
    position: relative;
    width: 100%;
    padding: 0 20px;
  }
  .site_information li:nth-child(1),
  .site_information li:nth-child(2) {
    visibility: hidden;
  }

  .site_information ul {
    float: right;
    width: 95%;
    padding: 21px 0 0;
  }
  .social_icon {
    margin-bottom: 30px;
  }

  .heading_main h2 {
    font-size: 30px;
  }
  .heading_main h2::after {
    margin-top: 10px;
  }
  .services_blog h4 {
    font-size: 20px;
    min-height: 70px;
    line-height: 70px !important;
  }
  .about_bg .text_align_right_img img {
    width: 100%;
  }
  .top-header #navbar-wd {
    padding-right: 0;
  }
  footer.footer-box p {
    margin-top: 5px;
    font-size: 13px;
  }
}
/* mobile or only mobile */
@media (max-width: 767px) {
  .site_information {
    float: left;
    width: 100%;
  }
  .site_information ul li:nth-child(1),
  .site_information ul li:nth-child(2) {
    display: none;
  }
  .site_information ul li {
    width: 100%;
  }
  .menu_orange_section {
    width: 100%;
    padding: 0 15px;
  }
  .site_information ul {
    width: 100%;
  }
  .header_bottom {
    position: relative;
    bottom: 0;
  }
  .search-box:hover .search-txt {
    width: 190px;
    padding: 0 10px;
  }
  .top-header.fixed-menu {
    position: relative;
  }
  .services_blog {
    margin-bottom: 50px;
  }
  .text_align_right_img img {
    float: right;
    width: 100%;
  }
  .about_bg .full.text_align_right_img {
    margin-top: 30px;
  }
  .news_blog {
    margin-bottom: 30px;
  }
  #scroll-to-top {
    bottom: 65px;
  }
  .footer-box .img-responsive {
    margin-bottom: 30px;
  }
  .top-header .navbar .navbar-collapse ul li a {
    padding: 5px 0;
    width: 100%;
  }
  .top-header .navbar {
    float: left;
    width: 100%;
  }
  .top-header .navbar .menu_section {
    width: 100%;
  }
  .header_bottom::after,
  .menu_orange_section::after {
    display: none;
  }
  .logo_section {
    width: 100%;
    text-align: center;
  }
  .navbar-brand {
    margin-left: 10px;
    margin-right: 10px;
  }
  .top-header .navbar .navbar-collapse ul li a {
    padding: 5px 15px;
  }
  .lbox-details h1 {
    font-size: 24px;
  }
  .lbox-details h2 {
    font-size: 18px;
  }
  .lbox-details p strong {
    font-size: 20px;
  }
  .lbox-details a.btn {
    display: none;
  }
  .title-box h2 {
    font-size: 38px;
  }
  .about-main-info h2 {
    font-size: 24px;
  }
  .about-img {
    margin-bottom: 30px;
  }
  .main-timeline-box .separline::before {
    left: 15px !important;
  }
  .main-timeline-box .iconBackground {
    left: 15px !important;
  }
  .main-timeline-box .timeline-text-content {
    margin-left: 0px;
  }
  .main-timeline-box .reverse .timeline-text-content {
    margin-right: 0px;
  }
  .main-timeline-box .time-line-date-content {
    margin-right: 0px;
  }
  .main-timeline-box .time-line-date-content p {
    float: left;
  }
  .main-timeline-box .timeline-element {
    padding: 0px 15px;
  }
  .gallery-box ul li {
    width: 48%;
  }
  .top-header {
    position: relative;
  }
  p.section_count {
    display: none;
  }
  .lbox-details {
    position: relative;
    width: 100%;
    padding: 5% 0%;
  }
  .about-m {
    margin-top: 30px;
  }
  .countdown #timer div#days,
  .countdown #timer div#hours,
  .countdown #timer div#minutes,
  .countdown #timer div#seconds {
    position: inherit;
    margin: 12px 0px;
  }
  .lbox-details::before {
    background: rgba(0, 0, 0, 0.9);
  }
  .countdown {
    height: 188px;
    border-radius: 0px;
  }
  .countdown #timer div {
    width: 23%;
    font-size: 24px;
  }
  .about-m {
    margin-bottom: 30px;
  }
  .timeLine .row .item {
    margin-bottom: 30px;
  }
  .filter-button-group button {
    margin-bottom: 5px;
  }
  .navbar-brand img {
    width: 175px;
  }
  .top-header {
    background-image: none;
    background-color: #ecedef;
  }
  .about-main-info div.full > img {
    height: auto;
    margin-bottom: 20px;
  }
  .search-box {
    right: 15px;
    top: 13px;
  }
  a.navbar-brand {
    left: 0;
    position: relative;
  }
  .slide_text {
    margin-top: 10%;
    display: none;
  }
  .slide_text h3 {
    font-size: 20px;
  }
  .slide_text h4 {
    font-size: 16px;
    margin: -20px 0;
  }
  .slide_text p {
    display: none;
  }
  .contact_bt {
    width: 90px;
    height: 35px;
    background: #07528d;
    color: #fff;
    float: left;
    line-height: 36px;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    margin-top: 5px;
  }
  .top-header #navbar-wd {
    padding-right: 0;
  }
  .heading_main {
    float: left;
    width: 100%;
  }
  .heading_main p.large {
    display: none;
  }
  h3.small_heading {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    margin-top: 25px;
  }
  #demo .img-responsive {
    margin: 10px 0;
    width: 100%;
  }
  .team_member_img img {
    border: solid #ece9e2 20px;
    border-radius: 100%;
    width: 280px;
    height: 280px;
  }
  .team_member_img.text_align_center {
    width: 280px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    float: none;
  }

  .bottom_menu {
    float: right;
    width: 100%;
    justify-content: center;
    display: flex;
  }

  .carousel a.carousel-control-prev {
    left: 20px;
  }

  .carousel a.carousel-control-next {
    right: 20px;
  }

  .footer_blog {
    margin-top: 25px;
  }
  .team_member_img .social_icon_team {
    position: absolute;
    top: 0;
    left: 0;
    width: 280px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: rgba(244, 56, 102, 0.7);
    opacity: 0;
    transition: ease all 0.2s;
  }
  .footer_bottom p.crp {
    float: left;
    width: 100%;
    text-align: center;
  }
  .heading_main h2 {
    font-size: 25px;
  }
}
div#branding {
  background: url(../img/background/6.png);
}
div#wallart {
  background: url(../img/background/7.png);
}
div#window {
  background: url(../img/background/5.png);
}
img.tile-img {
  height: 400px;
  width: 100%;
  padding: 0;
  cursor: pointer;
  transition: 0.3s;
}
.paddding_15 {
  padding: 15px;
}
div#pillar {
  background: url(../img/background/1.png);
}
div#wallbranding {
  background: url(../img/background/6.png);
}
div#signage {
  background: url(../img/background/2.png);
}
div#vehicle {
  background: url(../img/background/8.png);
}
div#novelties {
  background: url(../img/background/3.png);
}
div#pub {
  background: url(../img/background/4.png);
}
div#bar {
  background: url(../img/background/5.png);
}
div#lift {
  background: url(../img/background/7.png);
}
div.socials {
  color: #fff;
}
div.socials i {
  color: var(--white);
  padding: 4px 12px;
  display: inline-block;
  line-height: 10px;
  border-left: 1px solid #e9e9e9;
  font-size: xx-large;
  cursor: pointer;
}
div.socials i:hover {
  color: var(--green);
}
div.socials i:first-child {
  border-left: 0;
}
div#customer {
  background: url(../img//background/9.png);
}
div#homeIntro {
  background: url(../img/home/home.png);
  height: 400px;
}
div#defination {
  margin-top: 50px;
  padding: 50px;
}
